import React, { useContext } from "react"
import tw from "twin.macro"
import { LanguageContext } from "../../../contexts/Language"
import { FinanceCardProps } from "./OfferCard.d"

const FinanceCard: React.FC<FinanceCardProps> = ({
  termRates,
  familyVariant = false,
}) => {
  const { _ } = useContext(LanguageContext)
  /* Sorts by lowest rate and longest term and binds first item */
  const sortedRates =
    termRates?.sort(
      (a: { rate: number }, b: { rate: number }) => a?.rate - b?.rate,
    ) ?? []
  const lowestRates = sortedRates?.filter(
    offer => offer?.rate == sortedRates?.[0]?.rate,
  )
  const sortedTermRate = lowestRates?.sort(
    (a: { term: number }, b: { term: number }) => b.term - a.term,
  )?.[0]
  return (
    <section
      css={[tw`flex font-semibold`, familyVariant ? tw`mb-0` : tw`mb-6`]}
    >
      <div
        css={[
          tw`flex flex-col items-center gap-4 px-5 w-1/2 border-gray-600 border-r-2`,
          familyVariant && tw`gap-0 px-2`,
        ]}
      >
        {sortedTermRate && (
          <>
            <div css={[tw`text-green text-4xl`]}>
              {sortedTermRate?.rate}
              <sub css={[tw`text-base align-top`]}>%</sub>
            </div>
            <div
              css={[
                tw`tracking-widest uppercase`,
                "font-size: 0.65rem",
                familyVariant && "font-size: 0.55rem; letter-spacing: 0.2em;",
              ]}
            >
              {_("APR")}
            </div>
          </>
        )}
      </div>
      <div
        css={[
          tw`flex-1 flex flex-col items-center gap-4 px-5 w-1/2`,
          familyVariant && tw`gap-0 px-2`,
        ]}
      >
        {sortedTermRate && (
          <>
            <div css={[tw`text-green text-4xl`]}>{sortedTermRate?.term}</div>
            <div
              css={[
                tw`tracking-widest uppercase`,
                "font-size: 0.65rem",
                familyVariant && "font-size: 0.55rem; letter-spacing: 0.2em;",
              ]}
            >
              {_("MONTHS")}
            </div>
          </>
        )}
      </div>
    </section>
  )
}

export default FinanceCard
