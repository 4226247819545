import React, { useContext } from "react"
import tw from "twin.macro"
import { CashCardProps } from "./OfferCard.d"
import { toUsd } from "../../../helpers"
import { LanguageContext } from "../../../contexts/Language"

const FinanceCashCard: React.FC<CashCardProps> = ({
  cashAmount,
  type,
  familyVariant = false,
}) => {
  const { _ } = useContext(LanguageContext)
  return (
    <section css={[tw`flex gap-y-2 flex-wrap font-semibold`]}>
      {cashAmount && (
        <div
          css={[
            tw`flex justify-center text-center w-full text-green text-4xl font-semibold pt-2`,
            familyVariant && tw`pt-0`,
          ]}
        >
          <sub css={[tw`text-base align-top`]}>$</sub>
          {toUsd(cashAmount)}
        </div>
      )}
      <div
        css={[
          tw`tracking-widest uppercase font-semibold flex flex-col w-full text-center`,
          "font-size: 0.65rem",
          familyVariant && "font-size: 0.55rem; letter-spacing: 0.2em;",
        ]}
      >
        {_("Finance Cash", true)}
      </div>
      <div
        css={[
          tw`flex flex-col w-full text-xs text-center text-gray-700 font-light`,
        ]}
      >
        {_("Applies to multiple series")}
      </div>
    </section>
  )
}

export default FinanceCashCard
