import React, { useContext } from "react"
import tw from "twin.macro"
import { LeaseCardProps } from "./OfferCard.d"
import { toUsd } from "../../../helpers"
import { LanguageContext } from "../../../contexts/Language"

const LeaseCard: React.FC<LeaseCardProps> = ({
  monthlyPayment,
  dueAtSigningAmount,
  duration,
  familyVariant = false,
}) => {
  const { _ } = useContext(LanguageContext)
  return (
    <section css={[tw`flex gap-y-2 flex-wrap font-semibold`]}>
      <div
        css={[
          tw`flex flex-col items-center gap-4 px-5 w-1/2 border-gray-600 border-r-2`,
          familyVariant && tw`gap-0 px-2`,
        ]}
      >
        {monthlyPayment && (
          <>
            <div css={[tw`text-blue text-4xl`]}>
              <sub css={[tw`text-base align-top`]}>$</sub>
              {toUsd(monthlyPayment)}
            </div>
            <div
              css={[
                tw`tracking-widest whitespace-nowrap uppercase`,
                "font-size: 0.65rem",
                familyVariant && "font-size: 0.55rem; letter-spacing: 0.2em;",
              ]}
            >
              {_("PER MONTH")}
            </div>
          </>
        )}
      </div>
      <div
        css={[
          tw`flex-1 flex flex-col items-center gap-4 px-5 w-1/2`,
          familyVariant && tw`gap-0 px-2`,
        ]}
      >
        {duration && (
          <>
            <div css={[tw`text-blue text-4xl`]}>{duration}</div>
            <div
              css={[
                tw`tracking-widest uppercase`,
                "font-size: 0.65rem",
                familyVariant && "font-size: 0.55rem; letter-spacing: 0.2em;",
              ]}
            >
              {_("MONTHS")}
            </div>
          </>
        )}
      </div>
      <div css={[tw`w-full text-xs text-center text-gray-700 font-light`]}>
        <span>$</span>
        {toUsd(dueAtSigningAmount)} {_("due at signing")}
      </div>
    </section>
  )
}

export default LeaseCard
