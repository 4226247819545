import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react"
import Map, { Marker, Popup, MapRef, ViewStateChangeEvent } from "react-map-gl"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { LocationContext } from "../../../contexts/Location"
import { setSelectedDealer } from "../../../contexts/Location/actions"
import DealerTooltip from "./DealerTooltip"
import { DealerMapProps } from "./DealerMap.d"
import { css, Global } from "@emotion/react"
import tw from "twin.macro"

const DealerMap: React.FC<DealerMapProps> = ({
  dealers,
  allowPan,
  radius,
  mapContainerStyle,
  selectedMobileView = null,
  onSearchArea,
  ...remainingProps
}) => {
  const [mapHeight] = useState(mapContainerStyle?.height || "100%")
  const [{ selectedDealer }, dispatch] = useContext(LocationContext)
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState<number | null>(
    null,
  )
  const mapRef = useRef<MapRef | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [mapLoaded, setMapLoaded] = useState(false)
  const [showSearchButton, setShowSearchButton] = useState(false)
  const [initialCenter, setInitialCenter] = useState<{
    lng: number
    lat: number
  } | null>(null)
  const isZooming = useRef(false)
  const zoomTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const [viewport, setViewport] = useState({
    latitude: 32.7794,
    longitude: -86.8287,
    zoom: 6,
    bearing: 0,
    pitch: 0,
  })

  const fitMapToBounds = useCallback(() => {
    console.info("Fitting bounds")
    if (!dealers?.length || !mapRef.current || !mapLoaded) return

    try {
      isZooming.current = true
      if (zoomTimeoutRef.current) {
        clearTimeout(zoomTimeoutRef.current)
      }

      mapRef.current.resize()

      setTimeout(() => {
        if (!mapRef.current) return
        const bounds = new mapboxgl.LngLatBounds()

        dealers.forEach(dealer => {
          bounds.extend([dealer.Longitude, dealer.Latitude])
        })

        const sw = bounds.getSouthWest()
        const ne = bounds.getNorthEast()
        const latSpan = Math.max(ne.lat - sw.lat, 0.7) // Minimum 0.5 degree lat span
        const lngSpan = Math.max(ne.lng - sw.lng, 0.7) // Minimum 0.5 degree lng span

        // Apply padding with minimum bounds
        const paddedSw = [
          sw.lng - Math.max(lngSpan * 0.2, 0.1),
          sw.lat - Math.max(latSpan * 0.2, 0.1),
        ]
        const paddedNe = [
          ne.lng + Math.max(lngSpan * 0.2, 0.1),
          ne.lat + Math.max(latSpan * 0.2, 0.1),
        ]

        bounds.extend(paddedSw)
        bounds.extend(paddedNe)

        mapRef.current.fitBounds(bounds, {
          padding: {
            top: 150,
            bottom: 50,
            left: 50,
            right: 50,
          },
          duration: 1000,
          maxZoom: 15,
          offset: [0, -100],
        })

        const center = mapRef.current.getCenter()
        setInitialCenter({ lng: center.lng, lat: center.lat })

        zoomTimeoutRef.current = setTimeout(() => {
          isZooming.current = false
          mapRef.current?.resize()
        }, 1100)

        if (dealers.length === 1) {
          setTimeout(() => {
            if (mapRef.current) {
              mapRef.current.setZoom(12)
              mapRef.current.panTo(
                [dealers[0].Longitude, dealers[0].Latitude],
                {
                  offset: [0, -100],
                },
              )
              const singleCenter = mapRef.current.getCenter()
              setInitialCenter({ lng: singleCenter.lng, lat: singleCenter.lat })
            }
          }, 100)
        }
      }, 100)
    } catch (error) {
      console.error("Error fitting bounds:", error)
    }
  }, [dealers, mapLoaded])

  const handleMapMove = useCallback(
    (evt: ViewStateChangeEvent) => {
      setViewport(evt.viewState)

      if (!initialCenter || !mapRef.current || isZooming.current) return

      const currentCenter = mapRef.current.getCenter()

      const hasMovedSignificantly =
        Math.abs(currentCenter.lng - initialCenter.lng) > 0.1 ||
        Math.abs(currentCenter.lat - initialCenter.lat) > 0.1

      setShowSearchButton(hasMovedSignificantly)
    },
    [initialCenter],
  )

  const handleSearchArea = useCallback(() => {
    if (!mapRef.current || !onSearchArea) return

    // Set zooming flag before starting the animation
    isZooming.current = true
    if (zoomTimeoutRef.current) {
      clearTimeout(zoomTimeoutRef.current)
    }

    const center = mapRef.current.getCenter()
    onSearchArea({ lat: center.lat, lng: center.lng })

    // Reset after animation would complete
    zoomTimeoutRef.current = setTimeout(() => {
      isZooming.current = false
    }, 3000)

    setShowSearchButton(false)
    setInitialCenter({ lng: center.lng, lat: center.lat })
  }, [onSearchArea])

  const handleMapLoad = useCallback(() => {
    setMapLoaded(true)
    if (mapRef.current) {
      mapRef.current.resize()
      fitMapToBounds()
    }
  }, [fitMapToBounds])

  const handleMarkerClick = useCallback(
    (index: number) => {
      setSelectedMarkerIndex(index)
      dispatch(setSelectedDealer(dealers[index].Id))

      if (mapRef.current) {
        const currentZoom = mapRef.current.getZoom()
        mapRef.current.flyTo({
          center: [dealers[index].Longitude, dealers[index].Latitude],
          zoom: currentZoom,
          duration: 1000,
          offset: [0, -100],
        })
      }
    },
    [dealers, dispatch],
  )

  // Effects
  useEffect(() => {
    if (mapRef.current && mapLoaded) {
      const center = mapRef.current.getCenter()
      setInitialCenter({ lng: center.lng, lat: center.lat })
      setShowSearchButton(false)
    }
  }, [dealers, mapLoaded])

  useEffect(() => {
    if (mapLoaded) {
      fitMapToBounds()
    }
  }, [dealers, selectedMobileView, mapLoaded, fitMapToBounds])

  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: mapHeight,
        position: "relative",
        minHeight: "400px",
      }}
    >
      <Global
        styles={css`
          .no-max-width.mapboxgl-popup {
            max-width: none !important; /* Override the inline max-width */
          }
          .mapboxgl-popup-close-button {
            ${tw`text-3xl w-8 h-8 opacity-60`}
          }
          .mapboxgl-popup-content {
            ${tw`p-0`}
          }
        `}
      />

      {/* Search This Area Button */}
      {showSearchButton && (
        <button
          onClick={handleSearchArea}
          css={[
            tw`fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50`, // Mobile: centered at bottom
            tw`text-sm py-1.5 px-5 bg-black text-white rounded-full shadow-lg cursor-pointer`,
            tw`md:(absolute top-4 left-3/4 bottom-auto right-4 transform-none)`, // Desktop: top right
          ]}
        >
          Search This Area
        </button>
      )}

      <Map
        {...viewport}
        ref={mapRef}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
        mapStyle="mapbox://styles/mapbox/light-v11"
        mapboxAccessToken={process.env.GATSBY_MAPBOX_API_KEY}
        onMove={handleMapMove}
        onLoad={handleMapLoad}
        interactive={allowPan}
        onClick={() => setSelectedMarkerIndex(null)}
        reuseMaps
        {...remainingProps}
      >
        {dealers?.map((dealer, i) => (
          <React.Fragment key={dealer.Id || i}>
            <Marker
              latitude={dealer.Latitude}
              longitude={dealer.Longitude}
              anchor="bottom"
              onClick={e => {
                e.originalEvent.stopPropagation()
                handleMarkerClick(i)
              }}
            >
              <div
                style={{
                  position: "relative",
                  cursor: "pointer",
                  width: "20px",
                  height: "30px",
                }}
              >
                <img
                  src={
                    selectedDealer && selectedDealer.Id === dealer.Id
                      ? "/marker_red.svg"
                      : "/marker_black.svg"
                  }
                  alt="marker"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
                {radius && (
                  <div
                    style={{
                      position: "absolute",
                      top: "5px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      color: "white",
                      fontSize: "11.5px",
                      fontWeight: "bold",
                      textAlign: "center",
                      lineHeight: "1",
                    }}
                  >
                    {i + 1}
                  </div>
                )}
              </div>
            </Marker>

            {selectedMarkerIndex === i && (
              <Popup
                latitude={dealer.Latitude}
                longitude={dealer.Longitude}
                closeButton={true}
                closeOnClick={false}
                onClose={() => setSelectedMarkerIndex(null)}
                anchor="bottom"
                offset={[0, -15]}
                className="no-max-width"
              >
                <DealerTooltip dealer={dealer} i={i} />
              </Popup>
            )}
          </React.Fragment>
        ))}
      </Map>
    </div>
  )
}

export default React.memo(DealerMap)
