import { useContext, useEffect, useRef, useState } from "react"
import { LocationContext } from "../../contexts/Location"
import { LanguageContext } from "../../contexts/Language"
import { useTealiumContext } from "../../contexts/Tealium"
import initTealiumTracker from "tealium-tracker"
import { Buffer } from "buffer"

/**
 *
 * @author Stu
 * @summary This wraps the functionality of the tealium-tracker module.  Data passed via the viewData parameter is set to TealiumContext so it can be shared with Tealium event calls via the useTealiumEvent custom hook.
 * the hook is returned directly in order for the useState hook to function properly (i.e. hooks such as useState can only called at the top level of a functional react component, and not from within a standard funciton)
 *
 */

export type GlobalValues = {
  customer_ip?: string
  geolocation_zip?: string
  page_language?: string
  page_url?: string
  user_agent?: string
}

export type TealData = {
  full_page_name?: string
  page_name?: string
  vehicle_year?: string | number
  vehicle_model?: string
  page_type?: string
  vehicle_segment_vehicle_page?: string
  vehicle_model_truncated?: string
}

const isBrowser = typeof window !== "undefined"

const useTealiumView = () => {
  const [tealData, setTealData] = useState<TealData>(null)
  const [globalValues, setGlobalValues] = useState<GlobalValues>({})
  const [globalReady, setGlobalReady] = useState<boolean>(false)
  const { writeTealPageData } = useTealiumContext()
  const [locationState] = useContext(LocationContext)
  const [inititalZip, setInitialZip] = useState<string | null>(null)
  const { language } = useContext(LanguageContext)
  const [pageLoadEvent, setPageLoadEvent] = useState<boolean>(false)
  const languageFull =
    language === "en" ? "English" : language === "es" ? "Spanish" : "NOT SET"

  // domain name only
  const pageUrl = typeof window !== "undefined" ? window.location.hostname : ""
  // full page url
  const fullPageUrl = typeof window !== "undefined" ? window.location.href : ""

  // fetch cid parameter from url
  const params =
    typeof window !== "undefined" && new URLSearchParams(window.location.search)
  const cidParam = params && params?.get("cid")
  const inventoryParams = params && params?.get("s")
  const inventoryStateDecoded =
    inventoryParams &&
    JSON.parse(Buffer.from(inventoryParams, "base64").toString("utf8"))
  const setFFromParam =
    inventoryStateDecoded && inventoryStateDecoded.source
      ? inventoryStateDecoded.source
      : null
  const setFFromLocalStorage =
    typeof window !== "undefined" ? localStorage.getItem("source") : null

  const setFReferrer = setFFromParam || setFFromLocalStorage

  const firstLoad = useRef(true)

  // Preserve Geolocation Zip Code on first load on inventory page when setFReferrer is true
  useEffect(() => {
    if (firstLoad.current && setFReferrer && locationState?.geocoded_zip) {
      setInitialZip(locationState?.geocoded_zip)
      firstLoad.current = false
      return
    }
  }, [locationState])

  /* This function initializes globalValues state.
  - Between renders, it preserves previously set values using the prevState parameter in the setGlobalValues callback function
  - Global Values are initialized on pageload, and whenever the zip code or languages values are changed. */

  function initializeGlobalValues() {
    setGlobalValues(prevState => ({
      ...prevState,
      customer_ip:
        locationState?.user_ip ||
        locationState?.isOutOfRange?.user_ip ||
        (locationState?.errorMessage?.length > 0 && "NOT SET") ||
        null,
      geolocation_zip: setFReferrer
        ? inititalZip
        : locationState?.geocoded_zip ||
          locationState?.isOutOfRange?.zip ||
          (locationState?.errorMessage?.length > 0 && "NOT SET") ||
          null,
      page_language: languageFull,
      page_url: fullPageUrl,
      ...(cidParam && { campaign_name: cidParam }),
      ...(setFReferrer && {
        initial_referrer: "SETF",
        customer_zip: locationState?.geocoded_zip,
      }),
    }))
  }

  function handleView(viewData: Object) {
    setTealData(viewData)
    setPageLoadEvent(true)
  }

  useEffect(() => {
    initializeGlobalValues()
    // Global values are ready when these global values are available
    if (
      globalValues?.geolocation_zip &&
      globalValues?.page_language &&
      globalValues?.customer_ip &&
      !locationState.fetchingLocationData
    ) {
      setGlobalReady(true)
    }
  }, [
    locationState.geocoded_zip,
    globalValues.geolocation_zip,
    globalValues.page_language,
    locationState.user_ip,
    globalValues.customer_ip,
    locationState.isOutOfRange.user_ip,
    locationState.isOutOfRange.zip,
    locationState.fetchingLocationData,
  ])

  // Functions should fire only when all of the global property values are populated and tealData is not null
  useEffect(() => {
    if (isBrowser && globalReady && tealData && pageLoadEvent) {
      const { trackPageLoad } = initTealiumTracker()
      setPageLoadEvent(false)

      // Update TealiumContext with data from page load
      // Sets new page data / Does not re-use previous values

      writeTealPageData({
        customer_ip: "NOT SET",
        geolocation_zip: "NOT SET",
        page_type: tealData?.page_type,
        page_name: tealData?.page_name,
        full_page_name: `${pageUrl}:${tealData?.page_type}:${tealData?.page_name}`,
        ...(cidParam && { campaign_name: cidParam }),
        ...(tealData.offer_type && { offer_type: tealData.offer_type }),
        ...globalValues,
      })
      trackPageLoad({
        ...tealData,
        ...globalValues,
        full_page_name: `${pageUrl}:${tealData.page_type}:${tealData.page_name}`,
        ...(cidParam && { campaign_name: cidParam }),
      })
    }
  }, [tealData, globalReady, locationState.fetchingLocationData])

  return {
    handleTealView: handleView,
    initializeGlobalValues,
    globalValues,
    setGlobalValues,
  }
}

export default useTealiumView
