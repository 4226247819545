import { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { LocalStorageClient } from "../../../clients/LocalStorageClient"
import { LanguageContext } from "../../../contexts/Language"
import { LocationContext } from "../../../contexts/Location"
import { setErrorMessage, setZip } from "../../../contexts/Location/actions"
import { useTealiumContext } from "../../../contexts/Tealium"
import { Button } from "../../atoms/Button"
import { Loader, X } from "../../atoms/Icon"
import { Input } from "../../atoms/Input"
import { Tooltip } from "../../atoms/Tooltip"
import { UseMyLocation } from "../UseMyLocation"
import { LocationSelectorProps } from "./LocationSelector.d"

const LocationSelector: React.FC<LocationSelectorProps> = ({
  focusColor,
  textColor,
  location,
  fromBanner = false,
  inputOnly = false,
  setZipIsConfirmed,
  ...remainingProps
}) => {
  const [
    { zip, fetchingLocationData, dealersLoading, errorMessage, mapSearchZip },
    dispatch,
  ] = useContext(LocationContext)
  const { _ } = useContext(LanguageContext)
  const [searchInput, setSearchInput] = useState<string>("")
  const { analyticsId } = remainingProps
  const { setZipEvent } = useTealiumContext()

  const handleSubmit = () => {
    LocalStorageClient.delete("sourceZip")
    dispatch(setZip(searchInput))

    if (location === "global" && setZipEvent) {
      // Triggers a zipcode_go Tealium event
      setZipEvent({
        inProgress: true,
        prevZip: zip,
        searchInput,
        isGlobal: true,
      })
    } else if (location == "inventory") {
      setZipEvent({ inProgress: true, prevZip: zip, searchInput })
    } else if (setZipEvent) {
      // Triggers a zipcode_go Tealium event
      setZipEvent({ inProgress: true, prevZip: zip, searchInput })
    }
    LocalStorageClient.write("confirmedZip", true)

    if (fromBanner && setZipIsConfirmed) {
      setZipIsConfirmed(true)
    }
  }

  useEffect(() => {
    dispatch(setErrorMessage(null))
  }, [])

  useEffect(() => {
    const isZipConfirmed = LocalStorageClient.read("confirmedZip")
    // don't pre-fill input if previosuly entered zip is invalid
    if (errorMessage || isZipConfirmed === "false") {
      setSearchInput(`${""}`)
    } else {
      setSearchInput(`${mapSearchZip || zip || ""}`)
    }
  }, [zip, mapSearchZip])

  return (
    <div
      css={[
        tw`block`,
        tw`md:(flex)`,
        location == "inventory" ? tw`w-auto` : tw`w-full`,
      ]}
    >
      <div css={[tw`md:(mt-0)`]}>
        {fromBanner && (
          <div css={[tw`-mb-4 font-semibold text-black mt-2 pt-4`]}>
            {_("Confirm Location")}
          </div>
        )}
        {!inputOnly && (
          <label
            htmlFor="dealer_locator_input"
            css={[
              tw`text-xs pt-4 inline-block`,
              tw`lg:(mt-0)`,
              textColor === "white" ? tw`text-white` : tw`text-gray-900`,
            ]}
          >
            {_("Enter zip, state, city or dealer name")}
          </label>
        )}
        <div
          css={[
            tw`flex justify-between items-center overflow-visible pb-8 w-full border-b-gray-600`,
            tw`md:(pb-2 pt-2)`,
            inputOnly && tw`pb-0`,
            location == "inventory" &&
              tw`flex-wrap gap-4 md:(flex-nowrap gap-0) lg:w-min`,
          ]}
        >
          <div
            css={[
              tw`lg:(w-60) relative`,
              fromBanner && tw`lg:(w-20)`,
              location == "inventory" && tw`lg:(w-28)`,
            ]}
          >
            <Input
              css={[
                tw`w-full lg:(w-60 h-6) bg-transparent focus-visible:outline-white pr-3.5`,
                focusColor === "gray" && tw`focus-visible:outline-gray-50`,
                fromBanner && tw`lg:(w-20) text-black`,
                location == "inventory" &&
                  tw`pr-2 pb-2 h-10 md:pr-3.5 lg:(w-24 text-2xl font-semibold h-10)`,
              ]}
              type="text"
              name="dealer_locator_input"
              value={searchInput}
              onChange={e =>
                setSearchInput((e.target as HTMLInputElement).value)
              }
              onKeyDown={e => {
                if (e.key === "Enter") {
                  handleSubmit()
                }
              }}
            />
            <button
              css={tw`absolute right-0 top-1/2 transform -translate-y-1/2 text-gray-600`}
              onClick={() => setSearchInput("")}
              aria-label="Clear zip, state, city or dealer name"
            >
              <X color="gray-600" css={[tw`w-3`]} />
            </button>
            {errorMessage && (
              <Tooltip fromBanner={fromBanner} css={[tw`z-[90]`]}>
                {_(errorMessage)}
              </Tooltip>
            )}
          </div>
          {!inputOnly && (
            <div
              css={[
                fromBanner &&
                  tw`absolute flex-none bottom-6 left-4 lg:(flex static justify-center items-center bottom-auto left-auto)`,
                location === "inventory" &&
                  tw`order-2 left-0 md:order-1 md:left-4`,
              ]}
            >
              <UseMyLocation
                focusColor={focusColor}
                fromBanner={fromBanner}
                setZipIsConfirmed={setZipIsConfirmed}
                analyticsId={analyticsId}
                location={location}
              />
            </div>
          )}
          <Button
            primary={location == "inventory" ? false : true}
            secondary={location == "inventory" ? true : false}
            css={[
              tw`h-6 px-4 text-xs font-light ml-4 flex justify-center items-center`,
              tw`md:(outline-none)`,
              tw`focus-visible:(outline-white)`,
              focusColor === "gray" && tw`focus-visible:outline-gray-50`,
              location == "inventory" &&
                tw`order-1 ml-0 md:(order-2 ml-4 px-2)`,
            ]}
            onClick={handleSubmit}
            disabled={fetchingLocationData || dealersLoading}
            analytics-id={`zip find:${analyticsId}:`}
          >
            <span
              css={[
                tw`uppercase`,
                (fetchingLocationData || dealersLoading) && tw`hidden`,
                location == "inventory" && tw`font-semibold`,
              ]}
            >
              {location == "inventory" ? _("UPDATE") : _("FIND")}
            </span>
            <Loader
              color={location == "inventory" ? "black" : "white"}
              css={[
                tw`h-6 hidden animate-spin`,
                (fetchingLocationData || dealersLoading) && tw`block`,
              ]}
            />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default LocationSelector
